import React from "react";
import { Box, Button, Header, Heading, Image } from "grommet";
import Raffles from "./Raffles";
import { PeraWalletConnect } from "@perawallet/connect";
import { DeflyWalletConnect } from "@blockshake/defly-connect";
class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: "",
    };
  }

  render() {
    return (
      <Box flex={true}>
        <Header
          alignContent={"center"}
          responsive={true}
          justify={"around"}
          wrap
          direction={"row-responsive"}
        >
          <Box
            width={"xxlargelarge"}
            alignSelf={"start"}
            alignContent={"center"}
            style={{ fontStyle: "italic" }}
            flex={false}
            margin={"none"}
          >
            <Heading size={"medium"} color={"white"}>
              AQUATOPIA RAFFLES
            </Heading>
          </Box>
          <Box flex={false}>
            {this.state.accounts !== "" ? (
              <Box>
                <Button
                  primary
                  alignSelf={"center"}
                  margin={"medium"}
                  style={{ fontWeight: "bold" }}
                  label={
                    this.state.accounts.substring(0, 5) +
                    "..." +
                    this.state.accounts.substring(
                      this.state.accounts.length - 5
                    )
                  }
                />
              </Box>
            ) : (
              <div>
                <Button
                  primary
                  margin={"medium"}
                  alignSelf={"center"}
                  label="Connect Pera"
                  style={{ fontWeight: "bold" }}
                  id={"hoverStyle"}
                  onClick={async () => {
                    const peraWallet = new PeraWalletConnect();
                    peraWallet
                      .connect()
                      .then(async (newAccounts) => {
                        console.log(newAccounts[0]);
                        this.setState({
                          accounts: newAccounts[0],
                        });
                        localStorage.setItem("wallet", "pera")
                      })
                      .catch((e) => {
                        peraWallet
                          .reconnectSession()
                          .then(async (accounts) => {
                            if (accounts.length) {
                              this.setState({
                                accounts: accounts[0],
                              });
                              localStorage.setItem("wallet", "pera")
                            } else {
                              console.log(e);
                            }
                          })
                          .catch((e) => console.log(e));
                      });
                  }}
                />
                <Button
                  primary
                  margin={"medium"}
                  alignSelf={"center"}
                  label="Connect Defly"
                  style={{ fontWeight: "bold" }}
                  id={"hoverStyle"}
                  onClick={async () => {
                    const deflyWallet = new DeflyWalletConnect();
                    deflyWallet
                      .connect()
                      .then(async (newAccounts) => {
                        console.log(newAccounts[0]);
                        this.setState({
                          accounts: newAccounts[0],
                        });
                        localStorage.setItem("wallet", "defly")
                      })
                      .catch((e) => {
                        deflyWallet
                          .reconnectSession()
                          .then(async (accounts) => {
                            if (accounts.length) {
                              this.setState({
                                accounts: accounts[0],
                              });
                              localStorage.setItem("wallet", "defly")
                            } else {
                              console.log(e);
                            }
                          })
                          .catch((e) => console.log(e));
                      });
                  }}
                />
              </div>
            )}
          </Box>
        </Header>
        <Box>
          {this.state.accounts !== "" ? (
            <Raffles wallet={this.state.accounts} />
          ) : null}
        </Box>
      </Box>
    );
  }
}

export default Wallet;
