import {
  Box,
  Grommet
} from 'grommet';
import Wallet from "./components/Wallet";

const theme = {
  global: {
    colors: {
      brand: {
        "dark": "#EF9F26",
        "light": "#EF9F26"
      }
    },
    font: {
      family: 'Noto Sans',
      size: '18px',
      height: '20px',
    },
  },
  tip: {
    content: {
      background: 'black'
    },
    drop: {
      color: 'black',
    }
  }
};

function App() {
  return (
      <Grommet theme={theme} full={true} flex={false} background="url('background.jpg')">
        <Box>
          <Wallet/>
        </Box>
      </Grommet>
  );
}

export default App;
