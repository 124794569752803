import { Box, Button, Heading, RangeInput, Text, Tip } from "grommet";
import { useState } from "react";
import algosdk from "algosdk";
import MyAlgoConnect from "@randlabs/myalgo-connect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment-timezone";
import { PeraWalletConnect } from "@perawallet/connect";
import { DeflyWalletConnect } from "@blockshake/defly-connect";

function Raffle(raffle) {
  const [value, setValue] = useState(1);
  const algodClient = new algosdk.Algodv2(
    "",
    "https://node.algoexplorerapi.io",
    ""
  );

  async function enterRaffle() {
    try {
      raffle.loadingSet(true);
      if (raffle.props.walletEntries === raffle.props.walletLimit) {
        toast.error(
          "You already have the maximum numbers of entries for this raffle!"
        );
        raffle.loadingSet(false);
      } else if (
        +raffle.props.walletEntries + +value >
        raffle.props.walletLimit
      ) {
        toast.error(
          "You need to choose a lower number of tickets! You will go over the wallet limit!"
        );
        raffle.loadingSet(false);
      } else if (
        +value + +raffle.props.entries.length >
        raffle.props.ticketLimit
      ) {
        toast.error(
          "You need to choose a lower number of tickets. Not enough tickets available!"
        );
        raffle.loadingSet(false);
      } else {
        const params = await algodClient.getTransactionParams().do();
        const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
          suggestedParams: {
            ...params,
          },
          from: raffle.wallet,
          to: "RAFFLES6UQUASSXDNFSNEL6LPMY4BADYESZGMQ6SMA3I6VGO2KJQKBSHIU",
          assetIndex: 810972054,
          amount: value * raffle.props.price,
        });
        const optInTxn =
          algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
            suggestedParams: {
              ...params,
            },
            from: raffle.wallet,
            to: raffle.wallet,
            assetIndex: raffle.props.assetId,
            amount: 0,
          });
        const signedArray = [
          { txn: txn, signers: [raffle.wallet] },
          { txn: optInTxn, signers: [raffle.wallet] },
        ];
        let signedTxns;
        if (localStorage.getItem("wallet") === "defly") {
          const deflyWallet = new DeflyWalletConnect();
          await deflyWallet.reconnectSession();
          signedTxns = await deflyWallet.signTransaction([signedArray]);
        } else {
          const peraWallet = new PeraWalletConnect();
          await peraWallet.reconnectSession();

          signedTxns = await peraWallet.signTransaction([signedArray]);
        }
        signedTxns.map(
          async (signedTxn) =>
            await algodClient.sendRawTransaction(signedTxn).do()
        );
        await algosdk.waitForConfirmation(
          algodClient,
          txn.txID().toString(),
          4
        );
        await axios.post(
          `${process.env.REACT_APP_API_BASE}/raffles/${raffle.props.id}`,
          {
            raffleId: raffle.props.id,
            wallet: raffle.wallet,
            txId: txn.txID().toString(),
          }
        );
        toast(`Tickets Purchased!`);
        raffle.loadingSet(false);
      }
    } catch (e) {
      toast.error("Error Entering Raffle! Contact Admin");
      console.log(e);
      raffle.loadingSet(false);
    }
  }

  return (
    <Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box
        align="center"
        pad="medium"
        key={raffle.props.assetId}
        round="medium"
        elevation="xlarge"
        margin="medium"
        direction="column"
        alignSelf="center"
        background={"rgba(17,66,73,0.78)"}
        animation={{ type: "fadeIn", size: "medium" }}
        width={"600px"}
        height={"800px"}
        border={{ color: "brand", size: "medium" }}
      >
        <Box
          align="center"
          justify="center"
          border={{ color: "brand", size: "large" }}
          background={{
            color: "light-3",
            image: `url('https://www.randswap.com/v1/nft-url/${raffle.props.assetId}')`,
          }}
          round="small"
          height={"medium"}
          width={"medium"}
          flex={false}
        />
        <Box
          align="center"
          justify="center"
          pad="none"
          margin="small"
          width={"xlarge"}
          alignContent={"center"}
          flex={false}
        >
          <Box
            alignContent={"center"}
            background={"brand"}
            round={"small"}
            pad={"small"}
            margin={"small"}
            width={"medium"}
            alignSelf={"center"}
            flex={false}
          >
            <Heading
              level={3}
              alignSelf={"center"}
              margin={"none"}
              color={"black"}
              textAlign={"center"}
            >
              {raffle.props.assetName}
            </Heading>
          </Box>
          {raffle.props.winner !== null ? (
            <Box wrap={true} flex={false} margin={"medium"}>
              <Heading level={2} margin={"none"} fill={false} color={"brand"}>
                Winner:{" "}
                {raffle.props.winner.substring(0, 5) +
                  "..." +
                  raffle.props.winner.substring(raffle.props.winner.length - 5)}
              </Heading>
            </Box>
          ) : null}
          {raffle.props.winner === null ? (
            <>
              <Box
                direction={"column"}
                alignContent={"center"}
                align={"center"}
              >
                <Box>
                  <Heading level={3} margin={"none"} fill={true}>
                    {`Tickets Left: ${
                      raffle.props.ticketLimit - raffle.props.entries.length
                    } / ${raffle.props.ticketLimit}`}
                  </Heading>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                direction={"column"}
                alignContent={"center"}
                align={"center"}
              >
                <Box>
                  <Heading level={3} margin={"medium"} fill={true}>
                    {`Tickets Sold:  ${raffle.props.entries.length} / ${raffle.props.ticketLimit}`}
                  </Heading>
                </Box>
              </Box>
            </>
          )}
          {raffle.props.winner === null ? (
            <Box direction={"row"} alignContent={"center"} align={"center"}>
              <Box>
                <Heading level={3} margin={"none"} fill={true}>
                  <br />
                  Ending:{" "}
                  {moment(raffle.props.endingAt)
                    .tz(moment.tz.guess())
                    .format("h:mma on DD-MMM")}
                  <br />
                </Heading>
              </Box>
            </Box>
          ) : null}

          {raffle.props.winner === null ? (
            <Box pad={"none"} width={"75%"}>
              <RangeInput
                value={value}
                onChange={(event) => setValue(event.target.value)}
                min={1}
                max={raffle.props.walletLimit - raffle.props.walletEntries}
                step={1}
              />
              <Text margin={"small"} alignSelf={"center"} weight={"bold"}>
                {value} Ticket(s) = {value * raffle.props.price} AQUA.
              </Text>
            </Box>
          ) : null}
          <Box
            align="center"
            justify="center"
            pad="none"
            direction="row-responsive"
            flex
            alignSelf="center"
            basis="xxsmall"
            gap="small"
            style={{ fontWeight: "bold" }}
            margin="xsmall"
          >
            {raffle.props.active === true ? (
              <Button
                label="Enter Raffle"
                color={"brand"}
                onClick={enterRaffle}
                focusIndicator={true}
              />
            ) : (
              <Button label="Raffle Over" disabled={true} />
            )}
          </Box>
          <Tip
            content={`There is a maximum of ${raffle.props.walletLimit} per wallet for this raffle. You have currently purchased ${raffle.props.walletEntries} tickets`}
          >
            <Button
              margin={"small"}
              label={`Your Tickets: ${raffle.props.walletEntries} / ${raffle.props.walletLimit}`}
              plain={true}
              style={{ fontWeight: "bold" }}
            />
          </Tip>
        </Box>
      </Box>
    </Box>
  );
}

export default Raffle;
