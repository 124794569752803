import {useEffect, useState} from "react";
import {Box, Heading, Spinner} from "grommet";
import Raffle from "./Raffle";

function Raffles(wallet) {
    function getRaffles() {
        return fetch(`${process.env.REACT_APP_API_BASE}/raffles/${wallet.wallet}`).then(data => data.json())
    }

    const [raffles, setRaffles] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        let mounted = true;
        getRaffles()
            .then(results => {
                if (mounted) {
                    setRaffles(results)
                }
            })
        return () => mounted = false;
    }, [loading])

    return (
        <Box>
            {loading === false ?
                <Box>
                    {raffles.length === 0 ?
                        <Box>
                            <Heading alignSelf={"center"} color={"white"}>Loading Raffles</Heading>
                        </Box> :
                        <Box>
                        <Box direction={"row"} justify={"center"}>
                            <Box alignContent={"center"} background={"brand"} round={"xlarge"} pad={"medium"}
                                 margin={"small"} width={"medium"} alignSelf={"center"}>
                                <Heading level={3} alignSelf={"center"} margin={"none"} color={"white"}>
                                    {raffles.hoot} AQUA
                                </Heading>
                            </Box>
                            <Box alignContent={"center"} background={"brand"} round={"xlarge"} pad={"medium"}
                                 margin={"small"} width={"medium"} alignSelf={"center"} onClick={() => {
                                 window.open('https://app.tinyman.org/#/swap?asset_in=810972054&asset_out=0')
                                 }
                            } id={"hoverStyle"}>
                                <Heading level={3} alignSelf={"center"} margin={"none"} color={"white"}>
                                    TINYMAN
                                </Heading>
                            </Box>
                            <Box alignContent={"center"} background={"brand"} round={"xlarge"} pad={"medium"}
                                 margin={"small"} width={"medium"} alignSelf={"center"} onClick={() => {
                                window.open('https://tinychart.org/asset/810972054')
                            }
                            } id={"hoverStyle"}>
                                <Heading level={3} alignSelf={"center"} margin={"none"} color={"white"}>
                                    TINYCHART
                                </Heading>
                            </Box>
                        </Box>
                        <Box align="center" justify="center" pad="small" height="medium"
                             flex={true} fill="vertical" alignContent={"center"}
                             direction="row" wrap overflow="auto">

                            {raffles.raffles.map((raffle) =>
                                (<Raffle props={raffle} key={raffle.assetId} wallet={wallet.wallet}
                                         loadingSet={setLoading}/>))}
                        </Box>
                        </Box>
                    } </Box> : <Box align="center" justify="center" pad="large">
                    <Spinner size='xlarge'/>
                </Box>}
        </Box>
    );
}

export default Raffles;
